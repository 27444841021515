<template>
  <div style="background-color: #171726;min-height: 100vh;overflow: scroll;">
    <div class="title_top">
      <nav-bar :icon="'img/backb.png'" :textColor="'#0A2042'" :backColor="'#F7F9FE'" />
      <div class="padding-lr-sm margin-bottom-xl">
        <div class="record_item padding-tb" v-for="(item, index) in listData" :key="index">
          <div class="display_flex justify-content_flex-justify align-items_center">
            <p>收款支付宝：{{ item.cheques_number }}</p>
            <p>提现:￥{{ item.real_amount }}</p>
          </div>
          <div class="display_flex justify-content_flex-justify align-items_center margin-top-sm">
            <span>{{ item.create_time }}</span>
            <div v-if="item.status == 1" class="rd_color1">申请中</div>
            <div v-if="item.status == 3" class="rd_color2">已通过</div>
            <div v-if="item.status == 2" class="display_flex align-items_center">
              <div class="rd_color3">拒绝</div>
              <img class="margin-left-xs rd_ques" src="img/question.png" alt="" @click="whyNo(item.remark)">
            </div>
          </div>
        </div>
        <div class="noData margin-top-xl" v-if="noData">
          <img src="img/noData.png" alt="">
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/index.css"
import { withdrawalRecord } from "@/api/api";
import NavBar from '@/components/navBar.vue';
export default {
  name: 'record',
  components: {
    NavBar
  },
  data() {
    return {
      listData: [],
      noData: false
    };
  },
  created() {
    this.fetchData()
  },
  methods: {

    fetchData() {
      withdrawalRecord().then(response => {
        this.listData = response.data
        if (response.data.length == 0) {
          this.noData = true
        }
        console.log(response)
      },
        error => {
          this.$toast.fail(error)
        }).catch((error) => {
          this.$toast.fail(error)
        })
    },
    whyNo(remark) {
      this.$toast.fail(remark)
    }

  },
};
</script>
<style>
.van-toast__text {
  white-space: normal;
  word-break: break-all;
}
</style>